<template>
    <div id="ulog-page" @click="hideMsg">
        <div id="form-container" v-if="!ulog">
            <h1>Login</h1>
            <v-form v-model="formValid">
                <v-text-field
                    v-model="email"
                    :rules="[emailRules.required, emailRules.valid]"
                    label="Email"
                    required
                ></v-text-field>
                <v-text-field
                    v-model="password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[passRules.required, passRules.min, passRules.max]"
                    :type="show1 ? 'text' : 'password'"
                    label="Password"
                    @click:append="show1 = !show1"
                    @keydown.enter="submitWithEnterButton"
                    required
                ></v-text-field>
                <div id="error-login">
                    <h3>Invalid Credentials. Try again.</h3>
                </div>
                <v-btn id="login-btn"
                    :disabled="!formValid"
                    color="primary" 
                    @click="loginRequest">
                        Log In
                </v-btn>
        </v-form>
        </div>
        <div id="pass-change" v-if="ulog" @click="hideToolsMsg()">
            <h1>Change password</h1>
            <v-form v-model="passwordFormValid">
                <v-list-item>
                    <v-list-item-content>
                        <v-text-field
                            v-model="oldPass"
                            :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPass1 ? 'text' : 'password'"
                            @click:append="showPass1 = !showPass1"
                            placeholder="At least 6 characters"
                            :rules="[passRules.required, passRules.min, passRules.max]"
                            label="Current Password"
                        ></v-text-field>
                        <v-text-field
                            v-model="newPass"
                            :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPass2 ? 'text' : 'password'"
                            @click:append="showPass2 = !showPass2"
                            placeholder="At least 6 characters"
                            :rules="[passRules.required, passRules.min, passRules.max]"
                            label="New Password"
                        ></v-text-field>
                        <v-text-field
                            v-model="confirmPass"
                            :append-icon="showPass3 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPass3 ? 'text' : 'password'"
                            @click:append="showPass3 = !showPass3"
                            placeholder="Write password again"
                            :rules="[confirmRules.required, confirmRules.match]"
                            label="Confirm New Password"
                            @keydown.enter="submitWithEnterButton"
                        ></v-text-field>
                        <h2 id="pass-error-message">{{errorMsg}}</h2>
                    </v-list-item-content>
                </v-list-item>
                <v-btn
                    id="save-pass-btn"
                    color="success"
                    :disabled="!passwordFormValid"
                    @click="updatePass"
                    >
                    Save
                </v-btn>
            </v-form>
        </div>
        <div id="user-tools" v-if="ulog">
            <h1>Tools</h1>
            <div id="email-list-btns">
                <DialogSendButton 
                    class="user-tools-list-btns" 
                    btnName="Send alert member list" 
                    listType="campaign" 
                    @sendSuccess="toolSendSuccess()" 
                    @sendFail="toolSendFail(error)"
                />
                <DialogSendButton 
                    class="user-tools-list-btns" 
                    btnName="Send volunteer member list" 
                    listType="volunteer" 
                    @sendSuccess="toolSendSuccess()" 
                    @sendFail="toolSendFail(error)"
                />
                <v-btn
                    class="user-tools-list-btns"
                    elevation="12"
                    color="error"
                    @click="updateMemberOverlay = !updateMemberOverlay"
                    >
                    Update list member info
                </v-btn>
            </div>
            <h3 id="tools-msg">{{toolsMsg}}</h3>
        </div>
        <v-overlay
            id="update-member-overlay"
            v-show="ulog"
            :z-index="zIndex"
            :value="updateMemberOverlay"
            :opacity="opacity"
            :absolute="absolute"
            >
            <div id="update-member-form">
                <EditMemberTable @changeOverlay="updateMemberOverlay = !updateMemberOverlay"/>
            </div>
        </v-overlay>
    </div>
</template>

<script>
import axios from 'axios'
import cookies from 'vue-cookies'
import router from '../router'
import DialogSendButton from '../components/DialogSendButton.vue'
import EditMemberTable from '../components/EditMemberTable.vue'

    export default {
        name: "Ulog",
        components: {
            DialogSendButton,
            EditMemberTable,
        },
        computed: {
            sessionCookie() {
                return cookies.get('_casLt') 
            },
        },
        mounted() {
            if (this.sessionCookie != undefined){
                this.ulog = true
            }
        },
        data() {
            return {
                ulog: false,
                email: '',
                emailRules: {
                    required: v => !!v || 'E-mail is required',
                    valid: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                },
                password: '',
                passRules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 6 || 'Min 6 characters',
                    max: v => v.length <= 50 || 'Max 50 characters',
                },
                confirmRules: {
                    required: v => !!v || 'Password is required',
                    match: v => v == this.newPass|| "Password must match"
                },
                formValid: false,
                show1: false,
                passwordFormValid: false,
                oldPass: '',
                newPass: '',
                confirmPass: '',
                errorMsg: '',
                showPass1: false,
                showPass2: false,
                showPass3: false,
                toolsMsg: '',
                updateMemberOverlay: false,
                pageOverlayFlow: false,
                overlay: false,
                zIndex: 98,
                opacity: 1,
                absolute: true,
            }
        },
        methods: {
            hideMsg() {
                if (document.getElementById('error-login') != null) {
                    document.getElementById('error-login').style.display = "none";
                }
                if (document.getElementById('pass-error-message') != null) {
                    document.getElementById('pass-error-message').style.display = "none";
                }
            },
            hideToolsMsg() {
                if (document.getElementById('tools-msg') != null) {
                    document.getElementById('tools-msg').style.display = "none";
                }
            },
            //allows user to submit the form by hitting enter button while inputs are selected 
            submitWithEnterButton() {
                this.loginRequest();
            },
            //logout located in footer component
            loginRequest() {
                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/login',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        'email': this.email,
                        'password': this.password
                    }
                }).then((response) => {
                    let token = response.data.loginToken;
                    cookies.set('_casLt', token);
                    //releoads to last page with all components updated for logged in user
                    router.go(-1)
                }).catch((error) => {
                    this.errorMsg = error.response;
                    document.getElementById('error-login').style.display = "block";
                });
            },
            updatePass() {
                let token = cookies.get('_casLt');

                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/password',
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        "loginToken": token,
                        "password": this.oldPass,
                        "newPassword": this.newPass,
                    }
                }).then(() => {
                    this.newPass = '';
                    this.oldPass = '';
                    this.confirmPass = '';
                    cookies.remove('_casLt');
                    router.go()
                }).catch((error) => {
                    this.errorMsg = error.response.data;
                    console.log(this.errorMsg);
                    document.getElementById('pass-error-message').style.display = "block";
                })
            },
            toolSendSuccess() {
                this.toolsMsg = "Email has been sent to you!"
                document.getElementById('tools-msg').style.display = "block"
            },
            toolSendFail(error) {
                this.toolsMsg = error
                document.getElementById('tools-msg').style.display = "block"
            },
        }
    }
</script>

<style lang="scss" scoped>

    #ulog-page.hideOverflow {
        height: 50vh;
        overflow-y: hidden;
    }

    #ulog-page {
        width: 100vw;
        background-color: white;
        min-height: calc(100vh - 140px);
        margin-top: 70px; //account for fixed nav-bar height
        margin-bottom: 70px; //account for absolute footer height
        display: grid;
        justify-items: center;
        align-items: center;
        font-family: "Helvetica";
        box-shadow: 0 5px 15px  rgb(131, 131, 131);

        #form-container {
            width: 95vw;
            background-color: #e89f4c21;
            padding: 20px;
            border-radius: 30px;
            text-align: center;

            #error-login {
                display: none;
                color: red;
                padding-bottom: 10px;
            }

            #login-btn {
                padding: 0 30px;
            }
        }

        #pass-change {
            width: 95vw;
            background-color: #e89f4c21;
            padding: 20px;
            border-radius: 30px;
            text-align: center;
            margin-bottom: 40px;

            #pass-error-message {
                display: none;
                color: red;
                padding-bottom: 10px;
            }

            #save-pass-btn {
                padding: 0 30px;
            }
        }

        #user-tools {
            width: 95vw;
            background-color: #e89f4c21;
            padding: 20px;
            border-radius: 30px;
            text-align: center;
            margin-bottom: 40px;
            
            h1 {
                margin-bottom: 30px;
            }

            #email-list-btns {
                display: grid;
                justify-items: center;

                .user-tools-list-btns{
                    margin-bottom: 30px;
                }
            }
        }
    }

@media screen and (min-width: 600px) {
    #ulog-page {
        margin-bottom: 150px; //account for absolute footer height

        #form-container, #pass-change, #user-tools {
            width: 70vw;
        }
    }
}

@media screen and (min-width: 1000px) {
    #ulog-page {
        max-width: 1350px;
        margin-top: 80px; //account for fixed nav-bar height

        #form-container {
            width: 600px;


            #login-btn {
                padding: 0 60px;
            }
        }

        #pass-change {
            width: 600px;

            #save-pass-btn {
                padding: 0 60px;
            }
        }

        #user-tools {
            width: 600px;
        }
    }
}
</style>