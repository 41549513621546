<template>
    <div id="member-overlay">
        <h1>Edit a members details</h1>
        <div id="get-member-details">
            <v-form id="get-member-details-form" v-model="detailsFormValid">
                <v-select
                    v-model="listTableChoice"
                    :items="listTableOptions"
                    :rules="[emailRules.required]"
                    label="Choose Table"
                    >
                </v-select>
                <v-text-field
                    v-model="inputEmail"
                    :rules="[emailRules.required, emailRules.valid, emailRules.max]"
                    label="Members E-mail"
                    color="primary"
                ></v-text-field>
                <div id="details-error-message">
                    <h3>{{detailsErrorMsg}}</h3>
                </div>
                <v-btn id="create-btn"
                    :disabled="!detailsFormValid"
                    color="#e89f4c" 
                    @click="getUserDetails">
                        Get Member Details
                </v-btn>
            </v-form>
        </div>
        <div id="campaign-member-details" v-show="listTableChoice == 'Campaign Alert Table' && this.secondaryForm == true">
            <v-form id="campaign-member-details-form" v-model="campaignFormValid">
                <v-text-field
                    v-model="userFirstName"
                    :rules="[nameRules.required, nameRules.min, nameRules.max]"
                    label="First Name"
                    color="primary"
                ></v-text-field>
                <v-text-field
                    v-model="userLastName"
                    :rules="[nameRules.required, nameRules.min, nameRules.max]"
                    label="Last Name"
                    color="primary"
                ></v-text-field>
                <v-text-field
                    v-model="userEmail"
                    :rules="[emailRules.required, emailRules.valid, emailRules.max]"
                    label="E-mail"
                    color="primary"
                ></v-text-field>
                <v-text-field
                    v-model="userCity"
                    :rules="[nameRules.required, nameRules.min, nameRules.max]"
                    label="City/Town"
                    color="primary"
                ></v-text-field>
                <div id="campaign-error-message">
                    <h3>{{campaignErrorMsg}}</h3>
                </div>
                <div id="campaign-success-message">
                    <h3>{{campaignSuccessMsg}}</h3>
                </div>
                <div id="btn-container">
                    <v-btn id="update-member-btn"
                        :disabled="!campaignFormValid"
                        color="success" 
                        @click="updateCampaignMemberInfo">
                            Save
                    </v-btn>
                    <v-btn
                        id="delete-btn"
                        class="white--text"
                        color="error"
                        @click="hideShowBtns()"
                    >
                        Delete Member
                    </v-btn>
                    <v-btn
                        id="sure-btn"
                        class="white--text"
                        color="#FF0000"
                        @click="deleteMember()"
                    >
                        Click again to confirm delete 
                    </v-btn>
                </div>
            </v-form>
        </div>
        <div id="volunteer-member-details" v-show="listTableChoice == 'Volunteer Table' && this.secondaryForm == true">
            <v-form id="volunteer-member-details-form" v-model="volunteerFormValid">
                <v-text-field
                    v-model="userFirstName"
                    :rules="[nameRules.required, nameRules.min, nameRules.max]"
                    label="First Name"
                    color="primary"
                ></v-text-field>
                <v-text-field
                    v-model="userLastName"
                    :rules="[nameRules.required, nameRules.min, nameRules.max]"
                    label="Last Name"
                    color="primary"
                ></v-text-field>
                <v-text-field
                    v-model="userEmail"
                    :rules="[emailRules.required, emailRules.valid, emailRules.max]"
                    label="E-mail"
                    color="primary"
                ></v-text-field>
                <v-text-field
                    v-model="userPostal"
                    :rules="[postalRules.max]"
                    label="Postal Code"
                    color="primary"
                ></v-text-field>
                <v-text-field
                    v-model="userPhone"
                    :rules="[phoneRules.max]"
                    label="Phone"
                    color="primary"
                ></v-text-field>
                <v-textarea
                    v-model="userComments"
                    :rules="[commentsRules.max]"
                    auto-grow
                    maxlength="150"
                    counter
                    label="Comments"
                    color="primary"
                ></v-textarea>
                <div id="volunteer-error-message">
                    <h3>{{volunteerErrorMsg}}</h3>
                </div>
                <div id="volunteer-success-message">
                    <h3>{{volunteerSuccessMsg}}</h3>
                </div>
                <div id="btn-container">
                    <v-btn id="update-member-btn"
                        :disabled="!volunteerFormValid"
                        color="success" 
                        @click="updateVolunteerMemberInfo">
                            Save
                    </v-btn>
                    <v-btn
                        id="vol-delete-btn"
                        class="white--text"
                        color="error"
                        @click="volHideShowBtns()"
                    >
                        Delete Member
                    </v-btn>
                    <v-btn
                        id="vol-sure-btn"
                        class="white--text"
                        color="#FF0000"
                        @click="deleteMember()"
                    >
                        Click again to confirm delete 
                    </v-btn>
                </div>
            </v-form>
        </div>
        <div>
            <v-btn
                id="overlay-close-btn"
                class="white--text"
                color="primary"
                @click="changeOverlay()"
                >
                Close
            </v-btn>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import cookies from 'vue-cookies'

    export default {
        name: "EditMemberTable",
        data() {
            return {
                detailsFormValid: false,
                detailsErrorMsg: "",
                listTableOptions: ['Campaign Alert Table', 'Volunteer Table'],
                listTableChoice: "",
                inputEmail: "",
                emailRules: {
                    required: v => !!v || 'All sections required',
                    valid: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    max: v => v.length <= 50 || 'Max 50 characters'
                },
                nameRules: {
                    required: value => !!value || 'Name Required.',
                    min: v => v.length >= 1 || 'Min 1 character',
                    max: v => v.length <= 50 || 'Max 50 characters',
                },
                postalRules: {
                    max: v => v.length <= 7 || 'Max 7 characters',
                },
                phoneRules: {
                    max: v => v.length <= 20 || 'Max 20 characters',
                },
                commentsRules: {
                    max: v => v.length <= 150 || 'Max 150 characters',
                },
                campaignFormValid: false,
                campaignErrorMsg: "",
                campaignSuccessMsg: "",
                userFirstName: "",
                userLastName: "",
                userEmail: "",
                userPostal: "",
                userPhone: "",
                userComments: "",
                userCity: "",
                userPostalCode: "",
                secondaryForm: false,
                volunteerFormValid: false,
                volunteerErrorMsg: "",
                volunteerSuccessMsg: "",

            }
        },
        methods: {
            changeOverlay() {
                this.$emit("changeOverlay")
            },
            getUserDetails() {
                document.getElementById('details-error-message').style.display = "none"
                let token = cookies.get('_casLt');
                let table = ""
                
                if (this.listTableChoice == "Campaign Alert Table") {
                    table = "campaign"
                }
                else if (this.listTableChoice == "Volunteer Table"){
                    table = "volunteer"
                }

                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/email_lists',
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'loginToken': token,
                        'email': this.inputEmail,
                    },
                    params: {
                        'table': table,
                    }
                }).then((response) => {
                    if (table == "campaign") {
                        this.userFirstName = response.data.firstName;
                        this.userLastName = response.data.lastName;
                        this.userEmail = response.data.email;
                        this.userCity = response.data.city;
                    }
                    else if (table == "volunteer") {
                        this.userFirstName = response.data.firstName;
                        this.userLastName = response.data.lastName;
                        this.userEmail = response.data.email;
                        this.userPostal = response.data.postalCode;
                        this.userPhone = response.data.phone;
                        this.userComments = response.data.comments;
                    }
                    this.secondaryForm = true;
                }).catch((error) => {
                    this.detailsErrorMsg = error.response.data;
                    document.getElementById('details-error-message').style.display = "block";
                });
            },
            updateCampaignMemberInfo() {
                let token = cookies.get('_casLt');

                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/email_lists',
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        'loginToken': token,
                        'currentEmail': this.inputEmail,
                        'tableType': 'campaign',
                        'email': this.userEmail,
                        'firstName': this.userFirstName,
                        'lastName': this.userLastName,
                        'city': this.userCity
                    }
                }).then(() => {
                    this.campaignSuccessMsg = "Successfully updated the member.";
                    document.getElementById('campaign-error-message').style.display = "none";
                    document.getElementById('campaign-success-message').style.display = "block";
                }).catch((error) => {
                    this.campaignErrorMsg = error.response.data;
                    document.getElementById('campaign-error-message').style.display = "block";
                    document.getElementById('campaign-success-message').style.display = "none";
                });
            },
            updateVolunteerMemberInfo() {
                let token = cookies.get('_casLt');

                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/email_lists',
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        'loginToken': token,
                        'currentEmail': this.inputEmail,
                        'tableType': 'volunteer',
                        'email': this.userEmail,
                        'firstName': this.userFirstName,
                        'lastName': this.userLastName,
                        'postalCode': this.userPostal,
                        'phone': this.userPhone,
                        'comments': this.userComments,
                    }
                }).then(() => {
                    this.volunteerSuccessMsg = "Successfully updated the member.";
                    document.getElementById('volunteer-error-message').style.display = "none";
                    document.getElementById('volunteer-success-message').style.display = "block";
                }).catch((error) => {
                    this.volunteerErrorMsg = error.response.data;
                    document.getElementById('volunteer-error-message').style.display = "block";
                    document.getElementById('volunteer-success-message').style.display = "none";
                });
            },
            hideShowBtns() {
                document.getElementById("delete-btn").style.display = "none";
                document.getElementById("sure-btn").style.display = "block";
            },
            volHideShowBtns() {
                document.getElementById("vol-delete-btn").style.display = "none";
                document.getElementById("vol-sure-btn").style.display = "block";
            },
            deleteMember() {
                let token = cookies.get('_casLt')
                let tableType 
                if (this.listTableChoice == "Campaign Alert Table") {
                    tableType = "campaign"
                } else if (this.listTableChoice == "Volunteer Table"){
                    tableType = "volunteer"
                }

                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/email_lists',
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {
                        "loginToken": token,
                        "email": this.userEmail,
                        "tableType": tableType
                    }
                }).then(() => {
                    if (this.listTableChoice == "Campaign Alert Table") {
                        this.campaignSuccessMsg = "Successfully deleted the member.";
                        document.getElementById('campaign-error-message').style.display = "none";
                        document.getElementById('campaign-success-message').style.display = "block";
                    } else if (this.listTableChoice == "Volunteer Table") {
                        this.volunteerSuccessMsg = "Successfully deleted the member.";
                        document.getElementById('volunteer-error-message').style.display = "none";
                        document.getElementById('volunteer-success-message').style.display = "block";
                    }
                }).catch((error) => {
                    if (this.listTableChoice == "Campaign Alert Table") {
                        this.campaignErrorMsg = error.response.data;
                        document.getElementById('campaign-error-message').style.display = "block";
                    } else if (this.listTableChoice == "Volunteer Table") {
                        this.volunteerErrorMsg = error.response.data;
                        document.getElementById('volunteer-error-message').style.display = "block";
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    #member-overlay {
        display: grid;
        justify-items: center;

        h1 {
            color: #d57f1e;
        }

        #get-member-details {
            width: 100vw;
            max-width: 700px;
            padding: 70px 10px;
        }

        #campaign-member-details, #volunteer-member-details {
            width: 100vw;
            max-width: 700px;
            padding: 0 10px;
            display: grid;

            #btn-container {
                width: 100%;
                display: flex;
                justify-content: space-between;
                    
                    #delete-btn, #sure-btn, #vol-delete-btn, #vol-sure-btn {
                        text-align: end;
                    }

                    #sure-btn, #vol-sure-btn {
                        display: none;
                    }
            }
        }

        #get-member-details-form {
            #details-error-message {
                color: red;
                margin: 15px 0;
            }
        }

        #volunteer-error-message, #campaign-error-message {
            color: red;
            margin: 15px 0;
        }

        #campaign-success-message, #volunteer-success-message {
            color: green;
            margin: 15px 0;
        }

        #overlay-close-btn {
            width: 100%;
            height: 50px;
            max-width: 500px;
            margin-top: 50px;
            justify-self: center;
        }
    }
</style>