<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            width="500"
            transition="dialog-bottom-transition"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                >
                {{btnName}}
                </v-btn>
            </template>

            <v-card id="dialog-card">
                <v-btn
                    class="user-tools-list-btns"
                    elevation="12"
                    color="primary"
                    @click="sendEmailList(listType, fullList); dialog = false"
                    >
                    Get full list
                </v-btn>
                <v-btn
                    class="user-tools-list-btns"
                    elevation="12"
                    color="primary"
                    @click="sendEmailList(listType, emailOnly); dialog = false"
                    >
                    Get email list
                </v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import cookies from 'vue-cookies'

    export default {
        name: "DialogSendButton",
        props: ["btnName", "listType"],
        data () {
            return {
                dialog: false,
                emailOnly: false,
                fullList: true
            }
        },
        methods: {
            sendEmailList(listType, isFullList) {
                let token = cookies.get('_casLt');

                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/email_lists',
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'loginToken': token
                    },
                    params: {
                        "listType": listType,
                        "isFullList": isFullList,
                    }
                }).then(() => {
                    this.$emit("sendSuccess");
                }).catch((error) => {
                    this.$emit("sendFail", error.response.data);
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    #dialog-card {
        height: fit-content;
        padding: 50px 0;
        display: flex;
        justify-content: space-around;
        flex-flow: wrap;
        background-color: #d5801e;
        position: relative;

        .user-tools-list-btns {
            margin: 20px 0;
        }
    }
    
</style>